import http from 'axios'
import { toast } from 'react-toastify'
import {CelularNinja, CepNinja, CpfNinja, DataNinja, RemoveEspCard, ValorNinja} from '../helpers/DataNinja'


// const API = 'https://apids-sandbox.doacaosolutions.com.br/'
// const CHAVESECRETA = 'Bearer 4c9184f37cff01bcdc32dc486ec36961c4ca4238a0b923820dcc509a6f75849b'
//4c9184f37cff01bcdc32dc486ec369615f93f983524def3dca464469d2cf9f3e

const API = 'https://apids.doacaosolutions.com.br/'
const CHAVESECRETA = `Bearer ${localStorage.getItem('token')}`
const CLIENTEID = 1348
const CANALID = 17

const headers = { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json', 'Authorization': CHAVESECRETA }


export async function cadastrarColaborador(dados) {

    const cadastro = {
        nome_completo: dados.nomeCompleto,
        email: dados.email,
        celular: CelularNinja(dados.celular),
        status: dados.statusCadastro ? 'leed' : 'ativo'
    }

    if(dados.cpf){
        cadastro.cpf = CpfNinja(dados.cpf)
    }

    try {
        
        // const cadastroExistente = await http.get(`${API}colaboradores?cpf=${ CpfNinja(dados.cpf)}`, { headers })

        // if(cadastroExistente.data.itens.length){
        //     return cadastroExistente.data.itens[0]
        // }else{
        //     const response = await http.post(`${API}colaboradores`, cadastro, { headers })
        //         console.log(response)
        //         return response.data
        // }

        const response = await http.post(`${API}colaboradores`, cadastro, { headers })
        console.log(response)
        return response.data

        
    } catch (error) {
        toast.error('Não foi possivel cadastrar o colaborador')
    }
}

export async function doarCredito(dados) {

     const cadastro = await cadastrarColaborador(dados)
    // const cadastro = {
    //     id: 1233
    // }
    const params = {
        tipo: 'cartao_de_credito',
        valor: ValorNinja(dados.dadosColetados.valor),
        canal_id: CANALID,
        perfil_id: dados.dadosColetados.perfil,
        cadastro: {
            cadastro_id: cadastro.id
        },
        cartao_de_credito: {
            nome_impresso: dados.nomeCompleto,
            numero: RemoveEspCard(dados.cartao),
            bandeira: dados.bandeira,
            mes_validade: dados.validade.split('/')[0],
            ano_validade: dados.validade.split('/')[1]
        }
    }

    if (dados.dadosColetados.periodicidade == 'mensal') {

        params.cadastro.recorrencia = {
            tipo: 'cartao_de_credito',
            valor: ValorNinja(dados.dadosColetados.valor),
            ciclo_periodo: 1,
            ciclo_inicio: DataNinja(0, 'formato2'),
            ciclo_tipo: "meses",
            proxima_cobranca: DataNinja(0, 'formato2'),
            cartao_de_credito: {
                nome_impresso: 'Rafael Ribeiro',
                numero: RemoveEspCard(dados.cartao),
                bandeira: dados.bandeira,
                mes_validade: dados.validade.split('/')[0],
                ano_validade: dados.validade.split('/')[1]
            }
        }
    }

    try {
        const cartao = await http.post(`${API}cartoes`, params, { headers })
        return cartao
    } catch (error) {
        toast.error('Não foi possivel realizar a doação')
    }

}

export async function doarDebitoConta(dados) {

    const codigoBanco = 341 //ITAÚ
    const cadastro = await cadastrarColaborador(dados)


    const params = {
        valor: ValorNinja(dados.dadosColetados.valor),
        canal_id: CANALID,
        perfil_id: dados.dadosColetados.perfil,
        cadastro: {
            cadastro_id: cadastro.id,
            recorrencia: {
                tipo: 'debito_em_conta',
                valor: ValorNinja(dados.dadosColetados.valor),
                ciclo_periodo: 1,
                ciclo_inicio: DataNinja(7, 'formato2'),
                ciclo_tipo: "meses",
                proxima_cobranca: DataNinja(7, 'formato2'),
                conta: {
                    banco: {
                        codigo: codigoBanco
                    },
                    cpf_portador: CpfNinja(dados.cpf),
                    nome_completo: dados.nomeCompleto,
                    agencia: dados.agencia,
                    conta: dados.conta
                }
            }
        },
        data_agendamento: DataNinja(7, 'formato2'),
        conta: {
            banco: {
                codigo: codigoBanco
            },
            cpf_portador: CpfNinja(dados.cpf),
            nome_completo: dados.nomeCompleto,
            agencia: dados.agencia,
            conta: dados.conta
        }
    }

    try {
        const debitoConta = await http.post(`${API}debitocontas`, params, { headers })
        return debitoConta
    } catch (error) {
        toast.error('Não foi possivel realizar a doação')
    }
}

export async function doarBoleto(dados) {

     if(dados.dadosColetados.periodicidade == 'mensal'){
        
        dados.recorrencia = {
         tipo: 'boleto',
         valor: ValorNinja(dados.dadosColetados.valor),
         ciclo_periodo: 1,
         ciclo_inicio: DataNinja(0, 'formato2'),
         ciclo_tipo: 'meses',
         proxima_cobranca: DataNinja(0, 'formato2'),
         boleto: {
            tipo_envio: 2,
            tipo_boleto: 'avulso'
          }
       }
     }

    const endereco = await http.get(`https://viacep.com.br/ws/${CepNinja(dados.cep)}/json/`)
    const cadastro = await cadastrarColaborador(dados)

    const boleto = {
        canal_id: CANALID,
        perfil_id: dados.dadosColetados.perfil,
        especie_id: 18,
        vencimento: DataNinja(0, 'formato2'),
        template: "avulso",
        pagador: {
            cadastro_id: cadastro.id,
            celular: CelularNinja(dados.celular),
            cpf: cadastro.cpf,
            email: dados.email,
            endereco: {
                bairro: endereco.data.bairro,
                cep: CepNinja(dados.cep),
                cidade: endereco.data.localidade,
                complemento: endereco.data.complemento,
                logradouro: endereco.data.logradouro,
                numero: dados.numero,
                uf: endereco.data.uf
            },
            id: cadastro.id,
            nome_completo: dados.nomeCompleto,
            valor: ValorNinja(dados.dadosColetados.valor)
        },
        boletos: [
            {
                valor: ValorNinja(dados.dadosColetados.valor),
                data_vencimento: DataNinja(0, 'formato2'),
            }
        ]
    }

    try {
        const respBoleto = await http.post(`${API}boletos`, boleto, { headers })
        enviarEmail(respBoleto.data, cadastro)
        return respBoleto
    } catch(error){ 
        toast.error('Não foi possivel gerar o boleto')
    }

}

function enviarEmail(boleto, user) {
    console.log(user)
    http.post(`${API}boletos/pdf`,
        {
          cadastro_id: user.id,
          pagamento_id: boleto.id,
          template: "avulso",
        },
        {
          responseType: "blob",
          headers
        }
      )
      .then((event) => {
        if (event && event.status == 200) {
          let blob = new Blob([event.data], {
            filename: "boleto",
            type: "application/pdf",
          });
        //   var url = window.URL.createObjectURL(blob);

        //   window.open(url, "_blank");
          let formData = new FormData();
          formData.append("anexo", blob, "boleto.pdf");
          formData.append("template_tipo_id", 3);
          formData.append("cadastro_id", user.id);
          formData.append("nome", user.nome_completo);
          formData.append("assunto", "Seu Boleto");
          formData.append("emails", [user.email]);
          formData.append(
            "json",
            JSON.stringify({
              nome: user.nome_completo,
            })
          );
          http.post(`${API}notificacoes/email`, formData, {headers}).then((event) => {
            return;
          });
        }
      })
    
  }


  export async function login(operador, senha){
      

      try {
        const response = await http.post(`${API}autenticacao/token`,{
            tipo_autenticacao: 'autenticacao_operador',
            cliente_id: CLIENTEID,
            usuario: operador,
            senha: senha,
          }, {headers})
          
          if(response.status == 200){
              localStorage.setItem('token', response.data.token)
              const respToken = await http.get(`${API}operador/autenticado`,{ 
                headers:{
                        'X-Requested-With': 'XMLHttpRequest', 
                        'Content-Type': 'application/json', 
                        'Authorization': `Bearer ${response.data.token}`
                        }
                })
                
                if(respToken.status == 200){
                    localStorage.setItem('op', respToken.data.nome_completo)
                }
              return response
          }

      } catch (error) {
        toast.error('Não foi posssivel realizar o login')
      }

  }