import { Button, Divider, TextField, Typography } from "@material-ui/core"
import { useState } from "react"
import { toast } from "react-toastify"
import { cadastrarColaborador } from "../../api"



function CadastrarLead({aoEnviar}){

    const [nomeCompleto, setNomeCompleto] = useState('')
    const [cpf, setCPF] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [statusCadastro, setStatusCadastro] = useState('leed')

    return(
        <form style={{marginTop: '100px'}} onSubmit={(event)=>{
            event.preventDefault()
            const response = cadastrarColaborador({nomeCompleto, cpf, email, celular, statusCadastro})
            response.then(resp =>{
                if(resp){
                    setNomeCompleto('')
                    setEmail('')
                    setCelular('')
                    toast.success('Cadastro realizado com sucesso!')
                }
            })
        }}>

            <Typography color="primary" variant="h5">Novo Lead</Typography>
            <Divider variant="fullWidth" />

            <TextField
                value={nomeCompleto}
                onChange={(event) => { setNomeCompleto(event.target.value) }}
                id="nomeComplero"
                label="Nome Completo"
                type="text"
                fullWidth
                required
            />

            {/* <TextField
                value={cpf}
                onChange={(event) => { setCPF(event.target.value) }}
                id="cpf"
                label="CPF"
                type="text"
                fullWidth
                required
                onKeyUp={(event) =>{
                    event.target.maxLength = 14
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    setCPF(value)
                }}
            /> */}

            <TextField
                value={email}
                onChange={(event) => { setEmail(event.target.value) }}
                id="email"
                label="E-mail"
                type="email"
                fullWidth
                required
            />

            <TextField
                value={celular}
                onChange={(event) => { setCelular(event.target.value) }}
                id="celular"
                label="Celular"
                type="text"
                fullWidth
                required
                placeholder="(99) 99999-9999"
                onKeyUp={(event) =>{
                    event.target.maxLength = 15
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3')
                    setCelular(value)
                }}
            />

            <Button type="submit" style={{marginTop: '30px'}} variant="contained" color="primary" fullWidth>Cadastrar</Button>

        </form>
    )
}

export default CadastrarLead