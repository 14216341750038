
export function DataNinja(dias, formato) {
    let diasDoMes, dia, mes, ano, data 
  
    diasDoMes = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
    dia = dias ? new Date().getDate() + dias : new Date().getDate()
  
    if (dia > diasDoMes) {
      dia = 1
      mes = new Date().getMonth() + 2
    } else {
  
      mes = new Date().getMonth() + 1
    }
  
    ano = new Date().getFullYear()
  
    const diaFormat = dia <= 9 ? `0${dia}` : dia
    const mesFormat = mes <= 9 ? `0${mes}` : mes
  
    if(formato === 'formato1'){
       data = `${diaFormat}/${mesFormat}/${ano}`
    }
   
    if(formato === 'formato2'){
      data = `${ano}-${mesFormat}-${diaFormat}` 
    }
    return data
  }

  export function ValorNinja(valor){
    return valor.replace('.', '').replace(',','.')
  }

  export function CelularNinja(celular){
    return celular.replace('(','').replace(')','').replace('-','').replace(' ','')
  }

  export function CpfNinja(cpf){
    return cpf.replace('.', '').replace('.', '').replace('-', '')
  }

  export function CepNinja(cep){
    return cep.replace('-', '')
  }

  export function RemoveEspCard(cartao){
    return cartao.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')
  }


