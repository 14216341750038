import { Box, Button, Divider, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { doarBoleto } from "../../api";


function FormBoleto({ dadosColetados, statusDoacao, aoEnviar }) {  

    const [nomeCompleto, setNomeCompleto] = useState('')
    const [cpf, setCPF] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [cep, setCep] = useState('')
    const [numero, setNumero] = useState('')



    return (
        <form style={{ marginTop: '100px' }} onSubmit={(event) => {
            event.preventDefault()
            aoEnviar({ nomeCompleto, email, celular })
            const response = doarBoleto({ nomeCompleto, cpf, email, celular, cep, numero, dadosColetados })
            response.then(data =>{ statusDoacao(data.status == 201) })
        }}>
            <Typography color="primary" variant="h5">Doação com Boleto</Typography>
            <Divider variant="fullWidth" />

            <Typography color="primary" variant="h6" component="p">Valor: {dadosColetados.valor}</Typography>
            <Typography color="primary" variant="h6" component="p">Periodicidade: {dadosColetados.periodicidade}</Typography>

            <Typography style={{ marginTop: '20px' }} color="primary" variant="h6">Informações do doador</Typography>
            <Divider variant="fullWidth" />
            <TextField
                value={nomeCompleto}
                onChange={(event) => { setNomeCompleto(event.target.value) }}
                id="nomeComplero"
                label="Nome Completo"
                type="text"
                fullWidth
                required
            />

            <TextField
                value={cpf}
                onChange={(event) => { setCPF(event.target.value) }}
                id="cpf"
                label="CPF"
                type="text"
                fullWidth
                required
                placeholder="000.000.000-00"
                onKeyUp={(event) =>{
                    event.target.maxLength = 14
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    setCPF(value)
                }}
            />

            <TextField
                value={email}
                onChange={(event) => { setEmail(event.target.value) }}
                id="email"
                label="E-mail"
                type="email"
                fullWidth
                required
            />

            <TextField
                value={celular}
                onChange={(event) => { setCelular(event.target.value) }}
                id="celular"
                label="Celular"
                type="text"
                fullWidth
                maxLength={11}
                required
                placeholder="(99) 99999-9999"
                onKeyUp={(event) =>{
                    event.target.maxLength = 15
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3')
                    setCelular(value)
                }}
            />

            <Typography style={{ marginTop: '30px' }} color="primary" variant="h6">Endereço do doador</Typography>
            <Divider variant="fullWidth" />


            <Box display="flex" mt={2}>
                <Box p={1}>
                    <TextField
                        value={cep}
                        onChange={(event) => { setCep(event.target.value) }}
                        id="cartao"
                        label="CEP"
                        type="text"
                        placeholder="00000-000"
                        required
                        onKeyUp={event =>{
                            event.target.maxLength = 9
                            let value = event.target.value
                            value = value.replace(/\D/g, '')
                            value = value.replace(/^(\d{5})(\d)/, '$1-$2')
                            setCep(value)
                        }}
                    />
                </Box>
                <Box p={1}>
                        <TextField
                        value={numero}
                        onChange={(event) => { setNumero(event.target.value) }}
                        id="cartao"
                        label="Numero da casa"
                        type="number"
                        fullWidth
                        required
                        />
                        
                </Box>
            </Box>

            <Button style={{ marginTop: '30px' }} type="submit" variant="contained" color="primary" fullWidth>Finalizar doação</Button>

        </form>
    )
}


export default FormBoleto