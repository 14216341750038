import { Box, Button, Divider, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { doarDebitoConta } from "../../api";


function FormDebitoConta({ dadosColetados, statusDoacao, aoEnviar }) {  

    const [nomeCompleto, setNomeCompleto] = useState('')
    const [cpf, setCPF] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [agencia, setAgencia] = useState('')
    const [conta, setConta] = useState('')



    return (
        <form style={{ marginTop: '100px' }} onSubmit={(event) => {
            event.preventDefault()
            aoEnviar({ nomeCompleto, email, celular })
            const response = doarDebitoConta({ nomeCompleto, cpf, email, celular, agencia, conta, dadosColetados })
            response.then(data =>{ statusDoacao(data.status == 201) })
        }}>
            <Typography color="primary" variant="h5">Doação com débito em conta</Typography>
            <Divider variant="fullWidth" />

            <Typography color="primary" variant="h6" component="p">Valor: {dadosColetados.valor}</Typography>

            <Typography style={{ marginTop: '20px' }} color="primary" variant="h6">Informações do doador</Typography>
            <Divider variant="fullWidth" />
            <TextField
                value={nomeCompleto}
                onChange={(event) => { setNomeCompleto(event.target.value) }}
                id="nomeComplero"
                label="Nome Completo"
                type="text"
                fullWidth
            />

            <TextField
                value={cpf}
                onChange={(event) => { setCPF(event.target.value) }}
                id="cpf"
                label="CPF"
                type="text"
                fullWidth
                onKeyUp={(event) =>{
                    event.target.maxLength = 14
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    setCPF(value)
                }}
            />

            <TextField
                value={email}
                onChange={(event) => { setEmail(event.target.value) }}
                id="email"
                label="E-mail"
                type="email"
                fullWidth
            />

            <TextField
                value={celular}
                onChange={(event) => { setCelular(event.target.value) }}
                id="celular"
                label="Celular"
                type="text"
                fullWidth
                placeholder="(99) 99999-9999"
                onKeyUp={(event) =>{
                    event.target.maxLength = 15
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3')
                    setCelular(value)
                }}
            />

            <Typography style={{ marginTop: '30px' }} color="primary" variant="h6">Informações da conta</Typography>
            <Divider variant="fullWidth" />

            <Typography style={{ marginTop: '30px', fontSize: "16px" }} color="primary" variant="h6">Debito em conta autorizado somente para clientes ITAÚ</Typography>


            <Box display="flex" mt={2}>
                <Box p={1}>
                    <TextField
                        value={agencia}
                        onChange={(event) => { 
                            event.target.maxLength = 4
                            setAgencia(event.target.value) 
                        }}
                        id="cartao"
                        label="Agencia"
                        type="text"
                        placeholder="0000"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Box>
                <Box p={1}>
                        <TextField
                        value={conta}
                        onChange={(event) => { setConta(event.target.value) }}
                        id="cartao"
                        label="Conta com digito"
                        type="text"
                        placeholder="00000-0"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        onKeyUp={(event) =>{
                            event.target.maxLength = 7
                            let value = event.target.value
                            value = value.replace(/\D/g, '')
                            value = value.replace(/^(\d{5})(\d)/g,"$1-$2")
                            setConta(value)
                        }}
                        />
                        
                </Box>
            </Box>

            <Button style={{ marginTop: '30px' }} type="submit" variant="contained" color="primary" fullWidth>Finalizar doação</Button>

        </form>
    )
}


export default FormDebitoConta