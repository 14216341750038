import {Button, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography} from '@material-ui/core'
import { useState } from 'react'

function FazerDoacao({aoEnviar}) {
    const [valor, setValor] = useState('')
    const [periodicidade, setPeriodicidade] = useState('mensal')
    const [formaPagamento, setFormaPagamento] = useState('credito')
    const [perfil, setPerfil] = useState('380001443')


    return(
        <form style={{marginTop: '100px'}} onSubmit={
            (event) => {
                event.preventDefault()
                aoEnviar({valor, periodicidade, formaPagamento, perfil})
            }
        }>
            <Typography variant="h6" color="primary">Informe um valor</Typography>
            <Divider variant="fullWidth" />
            <TextField 
                value={valor}
                onChange={event => { 
                    setValor(event.target.value)
                }}

                onKeyUp={event =>{
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/(\d)(\d{2})$/, '$1,$2')
                    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
                    setValor(value)
                }}
                type="text" 
                id="valorDoacao" 
                placeholder="0,00"
                fullWidth 
                required
                variant="filled"
                style={{marginBottom: '30px', marginTop: '20px'}}
                InputLabelProps={{
                    shrink: true,
                  }}
                />

            <Typography variant="h6" color="primary">Qual a forma de doação?</Typography>
            <Divider variant="fullWidth" />
            <Divider variant="fullWidth" />

            <RadioGroup row aria-label="position" name="position" defaultValue="credito" onChange={event =>{
                setFormaPagamento(event.target.value)
            }}>
                <FormControlLabel value="credito" control={<Radio color="primary" />} label="Crédito"/>
                <FormControlLabel value="debConta" control={<Radio color="primary" />} label="Déb. conta" />
                <FormControlLabel value="boleto" control={<Radio color="primary" />} label="Boleto" />
            </RadioGroup>


            <div style={{display: `${formaPagamento !== 'debConta' ? 'block' : 'none'}`}}>
                <Typography style={{marginTop: '20px'}} variant="h6" color="primary">Qual tipo de doação sera realizada?</Typography>
                <Divider variant="fullWidth" />
                <RadioGroup row aria-label="position" name="position" defaultValue="mensal" onChange={event =>{
                    setPeriodicidade(event.target.value)
                    setPerfil(event.target.value == 'mensal' ? '380001443' :'380001444')
                }}>
                    <FormControlLabel value="mensal" control={<Radio color="primary" />} label="Doação Mensal"/>
                    <FormControlLabel value="unica" control={<Radio color="primary" />} label="Doação Unica" />
                </RadioGroup>
            </div>
            

            <Button style={{marginTop: '40px'}} type="submit" variant="contained" color="primary" fullWidth>Avançar</Button>
        
        </form>
    )
}

export default FazerDoacao