import { Button, Divider, Typography } from "@material-ui/core"
import { useState } from "react"
import { DataNinja } from "../../helpers/DataNinja"


function ComprovanteDoacao({dadosColetados, mudarStatus}){

    return(
        <form style={{marginTop: '100px'}} onSubmit={(event)=>{
            event.preventDefault()
            mudarStatus(false)
        }}>
            <Typography style={{marginBottom: '50px', color: 'green', display: `${dadosColetados.formaPagamento == 'credito' ? 'block' : 'none'}`}} align="center" variant="h5">DOAÇÃO REALIZADA COM SUCESSO</Typography>
            
            <Typography align="center" style={{marginBottom: '50px', color: 'green', display: `${dadosColetados.formaPagamento == 'debConta' ? 'block' : 'none'}`}}>
                    Agendamento de débito realizado com sucesso para o dia {DataNinja(7, 'formato1')}. 
                    O doador receberá um SMS do Itaú em nome de FPDS, basta o mesmo seguir as 
                    instruções para confirmar o débito.
            </Typography>

            <Typography align="center" style={{marginBottom: '50px', color: 'green', display: `${dadosColetados.formaPagamento == 'boleto' ? 'block' : 'none'}`}}>
                    Boleto gerado com sucesso e enviado para o E-mail fornecido.
            </Typography>
            
            <Typography color="primary" variant="h5">Informações da doação</Typography>
            <Divider variant="fullWidth" />

            <Typography variant="h6">Valor: {dadosColetados.valor}</Typography>
            <Typography variant="h6">Forma de doação: {dadosColetados.formaPagamento}</Typography>

            <Typography style={{display: `${dadosColetados.formaPagamento == 'debConta' ? 'none' : 'block'}`}} variant="h6">Periodicidade: {dadosColetados.periodicidade}</Typography>

            <Typography style={{marginTop: '20px'}} color="primary" variant="h5">Informações do doador</Typography>
            <Divider variant="fullWidth" />
            <Typography variant="h6">Nome: {dadosColetados.nomeCompleto}</Typography>
            <Typography variant="h6">E-mail: {dadosColetados.email}</Typography>
            <Typography variant="h6">Celular: {dadosColetados.celular}</Typography>
            

            <Button onClick={(event)=>{
                event.preventDefault()
                window.location = '/'
            }} style={{marginTop: '20px'}} variant="contained" color="primary" fullWidth>Nova doação</Button>

        </form>
    )
}


export default ComprovanteDoacao