import { Button, TextField, Zoom } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import axios from "axios";
import { Fragment, useState } from "react";
import { login } from "../../api";


function Login({statusLogin}) {

    const [operador, setOperador] = useState('')
    const [senha, setSenha] = useState('')


    return (
        <Fragment>
    
            <div style={{textAlign: 'center', marginTop: '80px'}}>
            <img 
                src="https://vocacao.doareacao.com.br/img/logo2.svg"
                style={{ width: '200px' }}
            />
            </div>
            

            <form style={{marginTop: '20px'}} onSubmit={(event)=>{
                event.preventDefault()
                // const CHAVESECRETA = 'Bearer RFBxcnRIelN4eFV1ejBieWJSMjkrQT09'
                // const headers = { 
                //     'X-Requested-With': 'XMLHttpRequest', 
                //     'Content-Type': 'application/json', 
                //     'Authorization': CHAVESECRETA
                //     }
                
                // axios.post('https://apids.doacaosolutions.com.br/autenticacao/token',{
                //     tipo_autenticacao: 'autenticacao_operador',
                //     cliente_id: 1345,
                //     usuario: 'suporte',
                //     senha: 'suporte@123',
                // }).then(resp=>{
                //     console.log(resp)
                // })


                const operadorLogado = login(operador, senha)
                operadorLogado.then(resp =>{
                    statusLogin(resp.status == 200)
                }).catch(error =>{
                    console.log(error)
                 })
            
        }}>
            <TextField
                value={operador}
                onChange={(event) =>{setOperador(event.target.value)}} 
                id="operador" 
                label="Operador" 
                type="text" 
                margin="normal" 
                fullWidth
                
            />

            <TextField
                value={senha}
                onChange={(event) =>{setSenha(event.target.value)}} 
                id="senha" 
                label="Senha" 
                type="password" 
                margin="normal" 
                fullWidth
                
            />

            <Button size="large" endIcon={<Send />} style={{marginTop: '30px'}} type="submit" variant="contained" color="primary" fullWidth>Entrar</Button>

        </form>
 
        </Fragment>
    )
}


export default Login