import { Box, Button, Divider, TextField, Typography } from "@material-ui/core"
import { useState } from "react"
import { doarCredito } from "../../api"
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { getCardFlag } from "../../helpers/DataNinja/getCardFlag"


function FormCredito({ dadosColetados, statusDoacao, aoEnviar }) {  

    const [nomeCompleto, setNomeCompleto] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [cartao, setCartao] = useState('')
    const [nomeImpresso, setNomeImpresso] = useState('')
    const [bandeira, setBandeira] = useState('')
    const [validade, setValidade] = useState('')
    
    return (    
        <form style={{ marginTop: '100px' }} onSubmit={(event) => {
            event.preventDefault()
            aoEnviar({ nomeCompleto, email, celular })
            const response = doarCredito({ nomeCompleto, email, celular, cartao, bandeira, nomeImpresso, validade, dadosColetados })
            response.then(data =>{ statusDoacao(data.status == 201) })
        }}>
            <Typography color="primary" variant="h5">Doação com cartão de crédito</Typography>
            <Divider variant="fullWidth" />

            <Typography color="primary" variant="h6" component="p">Valor: {dadosColetados.valor}</Typography>
            <Typography color="primary" variant="h6" component="p">Periodicidade: {dadosColetados.periodicidade}</Typography>

            <Typography style={{ marginTop: '20px' }} color="primary" variant="h6">Informações do doador</Typography>
            <Divider variant="fullWidth" />
            <TextField
                value={nomeCompleto}
                onChange={(event) => { setNomeCompleto(event.target.value) }}
                id="nomeComplero"
                label="Nome Completo"
                type="text"
                fullWidth
                required
            />

            <TextField
                value={email}
                onChange={(event) => { setEmail(event.target.value) }}
                id="email"
                label="E-mail"
                type="email"
                fullWidth
                required
            />

            <TextField
                value={celular}
                onChange={(event) => { setCelular(event.target.value) }}
                id="celular"
                label="Celular"
                type="text"
                fullWidth
                required
                placeholder="(99) 99999-9999"
                onKeyUp={(event) =>{
                    event.target.maxLength = 15
                    let value = event.target.value
                    value = value.replace(/\D/g, '')
                    value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3')
                    setCelular(value)
                }}
            />

            <Typography style={{ marginTop: '30px' }} color="primary" variant="h6">Informações do cartão</Typography>
            <Divider variant="fullWidth" />

            <Box display="flex">
                <Box flexGrow={1} style={{paddingRight: '10px'}}>
                <TextField
                        value={cartao}
                        onChange={(event) => { 
                            setCartao(event.target.value)
                            setBandeira(getCardFlag(event.target.value)) 
                        }}
                        id="cartao"
                        label="Numero do cartão de credito"
                        type="text"
                        fullWidth
                        placeholder={'0000 0000 0000 0000'}
                        required
                        onKeyUp={(event) =>{
                            event.target.maxLength = 23
                            let value = event.target.value
                            value = value.replace(/\D/g, '')
                            value = value.replace(/^(\d{4})(\d)/g,"$1 $2")
                            value = value.replace(/^(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3")
                            value = value.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3 $4")
                            setCartao(value)
                        }}
                    />
                </Box>
                <Box style={{zoom: '20%', marginTop: '60px'}}>
                    <Cards number={cartao} expiry={validade} name={nomeImpresso} cvc={'000'}/>
                </Box>
            </Box>
             

            <Box display="flex">
                <Box flexGrow={1} style={{paddingRight: '10px'}}>

                <TextField
                    value={nomeImpresso}
                    onChange={(event) => { 
                        setNomeImpresso(event.target.value) 
                    }}
                    id="nome_impresso"
                    label="Nome do titular"
                    type="text"
                    fullWidth
                    required
                />
                </Box>
                <Box>
                    <TextField
                    value={validade}
                    onChange={(event) => { 
                        setValidade(event.target.value) 
                    }}
                    id="validade"
                    label="Validade"
                    placeholder={'Mês / Ano'}
                    type="text"
                    fullWidth
                    required
                    onKeyUp={(event) =>{
                        event.target.maxLength = 5
                        let value = event.target.value
                        value = value.replace(/\D/g, '')
                        value = value.replace(/^(\d{2})(\d)/g,"$1/$2")
                        setValidade(value)
                    }}
                />
                </Box>
            </Box>

            

            

            <Button style={{ marginTop: '30px' }} type="submit" variant="contained" color="primary" fullWidth>Finalizar doação</Button>

        </form>
    )
}


export default FormCredito