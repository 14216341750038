import { AppBar, Avatar, BottomNavigation, Box, Button, Container, Drawer, IconButton, Toolbar, Typography } from "@material-ui/core"
import 'fontsource-roboto'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Home from "./components/FormularioCadastro/Home"
import {KeyboardBackspace, Menu } from '@material-ui/icons';

import { Fragment, useState } from "react";



function App() {

  const [operadorLogado, setOperadorLogado] = useState('')
  const [navBar, setNavBar] = useState('none')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [formAtivo, setFormAtivo] = useState('')

  return (
    <Fragment>

      <AppBar position="absolute" style={{ display: navBar }}>
        <Toolbar>

          <Box flexGrow={1}>
            <IconButton onClick={()=>{
              window.location = '/'
            }} style={{display: `${formAtivo == 'selecionarOpcao' ? 'none' : 'block'}`}} color="inherit">
              <KeyboardBackspace />
            </IconButton>
          </Box>
          <Box>
            <IconButton color="inherit" onClick={()=>setOpenDrawer(true)}>
              <Menu />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={openDrawer} onClose={()=>setOpenDrawer(false)}>
          <div style={{margin: '30px', textAlign: '-webkit-center'}}>
            <Avatar />
            <Typography style={{fontSize: '10px', marginTop: '5px'}}>operador</Typography>
            <Typography color="primary">{operadorLogado}</Typography>

            <Button
            style={{marginTop: '20px'}}
            color="primary" 
            variant="outlined"
            onClick={()=>{
              window.location = '/'
              localStorage.removeItem('token')
              localStorage.removeItem('op')
            }}
            >Sair</Button>
          </div>
      </Drawer>

      <Container maxWidth="sm">
        <ToastContainer />
        <Home
          formAtivo={setFormAtivo}
          operadorLogado={setOperadorLogado}
          navBar={setNavBar}
        />

      <BottomNavigation style={{marginTop: '20%'}}>
        <Typography align="center" style={{fontSize: '10px', color: '	#2F4F4F' }}>@copyright: Doação Solutions</Typography>
      </BottomNavigation>
      </Container>
    </Fragment>

  )
}


export default App;
