import { Fragment, useEffect, useState } from 'react'
import CadastrarLead from './CadastrarLead'
import ComprovanteDoacao from './ComprovanteDoacao'
import FazerDoacao from './FazerDoacao'
import FormBoleto from './FormBoleto'
import FormCredito from './FormCredito'
import FormDebitoConta from './FormDebitoConta'
import Login from './Login'
import SelecionarOpcao from './SelecionarOpcao'


function Home({operadorLogado, navBar, formAtivo }) {

    const [formAtual, setFormAtual] = useState('login')
    const [dadosColetados, setDados] = useState({})
    const [statusDacao, setStatusDacao] = useState(false)


    useEffect(()=>{
        
        formAtivo(formAtual)

        if(localStorage.getItem('token')  ){
            setFormAtual('selecionarOpcao')
            navBar('block')
            operadorLogado(localStorage.getItem('op'))
        }

        if(dadosColetados.opcao == 'painelDoacao'){
            setFormAtual('painelDoacao')    
        }

        if(dadosColetados.opcao == 'cadastrarLead'){
            setFormAtual('cadastrarLead')
        }

        if(dadosColetados.formaPagamento == 'credito'){
            setFormAtual('formCredito')
        }

        if(dadosColetados.formaPagamento == 'debConta'){
            setFormAtual('formDebitoConta')
        }

        if(dadosColetados.formaPagamento == 'boleto'){
            setFormAtual('formBoleto')
        }

        if(statusDacao){
            setFormAtual('comprovanteDoacao')
        }

    })

    const formulario = {
        login: <Login statusLogin={statusLogin}/>,
        selecionarOpcao: <SelecionarOpcao aoEnviar={coletarDados}/>,
        painelDoacao: <FazerDoacao aoEnviar={coletarDados}/>,
        formCredito: <FormCredito aoEnviar={coletarDados} dadosColetados={dadosColetados} statusDoacao={setStatusDacao}/>,
        formDebitoConta: <FormDebitoConta aoEnviar={coletarDados} dadosColetados={dadosColetados} statusDoacao={setStatusDacao}/>,
        formBoleto: <FormBoleto aoEnviar={coletarDados} dadosColetados={dadosColetados} statusDoacao={setStatusDacao}/>,
        cadastrarLead: <CadastrarLead aoEnviar={coletarDados}/>,
        comprovanteDoacao: <ComprovanteDoacao mudarStatus={setStatusDacao} dadosColetados={dadosColetados}/>
    }
    

    function statusLogin(status){
        if(status){
            setFormAtual('painelDoacao')
            navBar('block')
        }
    }

    function coletarDados(dados) {
        setDados({...dadosColetados, ...dados})
    }

    return (
        <Fragment>
            {formulario[`${formAtual}`]}
        </Fragment>
    )
}

export default Home