import { Button, Divider, Typography } from "@material-ui/core"
import { Favorite, InsertEmoticon } from "@material-ui/icons"
import { useState } from "react"


function SelecionarOpcao({aoEnviar}){

    const [opcao, setOpcao] = useState('')

    return(
        <form style={{marginTop: '100px'}} onSubmit={(event)=>{
            event.preventDefault()
            aoEnviar({opcao})
        }}>
        
        <Typography variant="h6" color="primary">Escolha uma opção</Typography>
            <Divider variant="fullWidth" />

        <Button startIcon={<Favorite />} size="large" onClick={()=>setOpcao('painelDoacao')} type="submit" style={{marginTop: '50px'}} variant="contained" color="primary" fullWidth>Realizar doação</Button>

        <Button startIcon={<InsertEmoticon />} size="large" onClick={()=>setOpcao('cadastrarLead')} type="submit" style={{marginTop: '30px', background: '#626a7e'}} variant="contained" color="primary" fullWidth>Cadastrar Lead</Button>
        </form>
    )
}

export default SelecionarOpcao